import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { CONFIGURATION_TYPES } from 'state-domains/constants';
import { Section } from 'state-domains/domain';
import {
    buildConfigurationHeaderDeleteSectionUrl,
    buildConfigurationSampleHeaderDeleteSectionUrl,
} from 'state-domains/network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from 'state-domains/utils';

export const deleteConfigurationHeaderSection = (
    headerId: string,
    sectionId: string,
    configType: CONFIGURATION_TYPES,
): Observable<Record<string, Section>> => {
    const url =
        configType === CONFIGURATION_TYPES.HEADERS
            ? buildConfigurationHeaderDeleteSectionUrl(headerId, sectionId)
            : buildConfigurationSampleHeaderDeleteSectionUrl(headerId, sectionId);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const sections = convertToCamel<Record<string, Section>>(response.sections);
            return observableOf(sections);
        }),
    );
};
